

  // jQuery('.custom1').owlCarousel({
  // loop:true,
  // infinte:true,
  // nav:true,
  // autoplay:true,
  // navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
  // dots:false,
  // items:3,
  // autoplayHoverPause:true,
  //    responsive:{
  //         0:{
  //             items:1,
  //             dots:false,
  //         },
  //         768:{
  //             items:2,
  //             dots:false,
  //         },
  //         1200:{
  //             items:3,

  //         }
  //     }

  // });
  // /*---------------------
  //     Sidebar sticky active
  // --------------------- */
  // jQuery('.sidebar-active').stickySidebar({
  //     topSpacing: 0,
  //     bottomSpacing: 0,
  //     minWidth: 767,

  // });
